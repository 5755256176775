<template>
	<div id="mian">
		<div>
			<div class="content">
				<div class="desc">
					<h5 class="jsxx">交易信息</h5>
					<div>
						<el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
					</div>
				</div>
				<ul class="listBox">
					<li><span>商户订单号</span><i class="hidden1">{{detail.mchOrderNo?detail.mchOrderNo:''}}</i></li>
					<li><span>交易流水号</span><i class="hidden1">{{detail.plfOrderNo?detail.plfOrderNo:''}}</i></li>
					<li><span>三方订单号</span><i class="hidden1">{{detail.upsOrderNo?detail.upsOrderNo:''}}</i></li>
					<li><span>三方流水号</span><i class="hidden1">{{detail.thdOrderNo?detail.thdOrderNo:''}}</i></li>
          <li><span>分账订单号</span><i class="hidden1">{{detail.mchAllocOrderNo?detail.mchAllocOrderNo:''}}</i></li>
					<li><span>分账流水号</span><i class="hidden1">{{detail.allocTrxNo?detail.allocTrxNo:''}}</i></li>
					<li><span>商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.mchName" placement="top">
              <b>{{detail.mchName}}</b>
            </el-tooltip>
          </i></li>
					<li><span>商户编号</span><i class="hidden1">{{detail.mchId?detail.mchId:''}}</i></li>
					<li><span>分账商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.allocMchName" placement="top">
              <b>{{detail.allocMchName}}</b>
            </el-tooltip>
          </i></li>
					<li><span>分账商户编号</span><i class="hidden1">{{detail.allocMchId?detail.allocMchId:''}}</i></li>
					<li><span>店铺名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.storeName" placement="top">
              <b>{{detail.storeName}}</b>
            </el-tooltip>
          </i></li>
					<li><span>店铺编号</span><i class="hidden1">{{detail.storeId?detail.storeId:''}}</i></li>
					<li><span>报备商户</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.tradeMchName" placement="top">
              <b>{{detail.tradeMchName}}</b>
            </el-tooltip>
          </i></li>
					<li><span>报备商户号</span><i class="hidden1">{{detail.tradeMchNo?detail.tradeMchNo:''}}</i></li>
					<li><span>代理商名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.agentName" placement="top">
              <b>{{detail.agentName}}</b>
            </el-tooltip>
          </i></li>
					<li><span>服务商名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.servicerName" placement="top">
              <b>{{detail.servicerName}}</b>
            </el-tooltip>
          </i></li>
					<li><span>所属机构</span><i class="hidden1">{{detail.orgName?detail.orgName:''}}</i></li>
					<li><span>渠道商户号</span><i class="hidden1">{{detail.channelMchNo?detail.channelMchNo:''}}</i></li>
					<li><span>创建时间</span><i class="hidden1">{{detail.createTime?detail.createTime:''}}</i></li>
					<li><span>交易时间</span><i class="hidden1">{{detail.tradeTime?detail.tradeTime:''}}</i></li>
					<li><span>产品类型</span><i class="hidden1">{{detail.productName?detail.productName:''}}</i></li>
					<li><span>交易状态</span><i class="hidden1">{{orderStatus[detail.orderStatus]}}</i></li>
					<li><span>交易金额</span><i class="hidden1">{{detail.orderAmount/100 | formatMoney}}</i></li>
					<li><span>手续费</span><i class="hidden1">{{detail.fee?detail.fee/100:'' | formatMoney}}</i></li>
					<li><span>分账金额</span><i class="hidden1">{{detail.allocAmount?detail.allocAmount/100:'' | formatMoney}}</i></li>
					<li><span>分账类型</span><i class="hidden1">{{allocFundType[detail.allocFundType]}}</i></li>
					<li><span>分账状态</span><i class="hidden1">{{allocStatus[detail.allocStatus]}}</i></li>
					<li><span>是否担保</span><i class="hidden1">{{detail.guaranteeStatus?'是':'否'}}</i></li>
					<li><span>商品名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.body" placement="top">
              <b>{{detail.body}}</b>
            </el-tooltip>
          </i></li>
					<li><span>商品详情</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.detail" placement="top">
              <b>{{detail.detail}}</b>
            </el-tooltip>
          </i></li>
					<li><span>微信OpenID</span><i class="hidden1">{{detail.openId?detail.openId:''}}</i></li>
					<li><span>微信APPID</span><i class="hidden1">{{detail.appId?detail.appId:''}}</i></li>
					<li><span>付款银行</span><i class="hidden1">{{bank[detail.bankCode]?bank[detail.bankCode]:detail.bankCode}}</i></li>
					<li><span>附加数据</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.attach" placement="top">
              <b>{{detail.attach}}</b>
            </el-tooltip>
          </i></li>
          <li><span>终端IP</span><i class="hidden1">{{detail.terminalIp?detail.terminalIp:''}}</i></li>
					<li><span>通知地址</span><i class="hidden1">
					  <el-tooltip class="item" effect="light" :content="detail.notifyUrl" placement="top">
					    <b>{{detail.notifyUrl}}</b>
					  </el-tooltip>
					</i></li>
          <li><span>页面内回调地址</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.returnUrl" placement="top">
              <b>{{detail.returnUrl}}</b>
            </el-tooltip>
          </i></li>
					<li><span>分账通知地址</span><i class="hidden1">
					  <el-tooltip class="item" effect="light" :content="detail.allocFundNotifyUrl" placement="top">
					    <b>{{detail.allocFundNotifyUrl}}</b>
					  </el-tooltip>
					</i></li>
          <li><span>响应码描述</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.resultMessage" placement="top">
              <b>{{detail.resultMessage}}</b>
            </el-tooltip>
          </i></li>
					<li><span>响应码</span><i class="hidden1">{{detail.resultCode?detail.resultCode:''}}</i></li>
				</ul>
			</div>
			<div class="desc">
        <h5 class="jsxx">交易通知信息</h5>
      </div>
			<div class="tab1">
				<table class="tab">
					<tr class="tab_title">
						<td>
							<p>通知时间</p>
						</td>
						<td>
							<p>请求类型</p>
						</td>
						<td>
							<p>通知状态</p>
						</td>
						<td>
							<p>通知结果</p>
						</td>
						<td>
							<p>状态码</p>
						</td>
						<td>
							<p>返回信息</p>
						</td>
					</tr>
					<tr v-for="(v,i) in orderNotices" :key="i">
						<td>
							<p>{{v.notifyTime}}</p>
						</td>
						<td>
							<p>{{notifyType[v.notifyType]}}</p>
						</td>
						<td>
							<p>{{v.reachStatus?'已到达':'未响应'}}</p>
						</td>
						<td>
							<p>{{notifyStatus[v.notifyStatus]}}</p>
						</td>
						<td>
							<p>{{v.resultCode}}</p>
						</td>
						<td>
							<p>{{v.resultMessage}}</p>
						</td>
					</tr>
				</table>
			</div>
			<div class="desc">
        <h5 class="jsxx">分账通知信息</h5>
      </div>
			<div class="tab1">
				<table class="tab">
					<tr class="tab_title">
						<td>
							<p>通知时间</p>
						</td>
						<td>
							<p>请求类型</p>
						</td>
						<td>
							<p>通知状态</p>
						</td>
						<td>
							<p>通知结果</p>
						</td>
						<td>
							<p>状态码</p>
						</td>
						<td>
							<p>返回信息</p>
						</td>
					</tr>
					<tr v-for="(v,i) in allocNotices" :key="i">
						<td>
							<p>{{v.notifyTime}}</p>
						</td>
						<td>
							<p>{{notifyType[v.notifyType]}}</p>
						</td>
						<td>
							<p>{{v.reachStatus?'已到达':'未响应'}}</p>
						</td>
						<td>
							<p>{{notifyStatus[v.notifyStatus]}}</p>
						</td>
						<td>
							<p>{{v.resultCode}}</p>
						</td>
						<td>
							<p>{{v.resultMessage}}</p>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getAllocfundDetail
	} from '@/api/trading/subaccount.js'
	import {
		getBank
	} from '@/utils/bank.js'
	export default {
		data() {
			return {
				crumbs: "交易详情",
				detail: '',
				orderNotices: [],
				allocNotices: [],
				bank: getBank(),
				orderStatus: {
					NOTPAY: '未支付',
					SUCCESS: '交易成功',
					REFUND: '转入退款',
					CLOSED: '订单关闭',
					PAYERROR: '支付失败',
					USERPAYING: '用户支付中',
					REVOKED: '已撤销'
				},
				allocFundType: {
					11: '实时分账',
					12: '延迟分账',
					13: '多次分账'
				},
				allocStatus: {
					PROCESSING: '分账处理中',
					FAIL: '分账失败',
					SUCCESS: '分账成功'
				},
				notifyType: {
					PLATFORM: '平台',
					THIRD: '第三方'
				},
				notifyStatus: {
					SUCCESS: '成功',
					FAIL: '失败'
				}
			}
		},
		created() {
			this.getDetail()
		},
		methods: {
			getDetail() {
				getAllocfundDetail(this.$route.query.id).then(res => {
					if (res) {
						this.detail = res.resultData.detail
						this.orderNotices = res.resultData.orderNotices
						this.allocNotices = res.resultData.allocNotices
					}
				})
			},
			//菜单切换
			navClick(data) {
				this.crumbs = data
			},
		}
	}
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
	.addBt {
		width: 60px;
		height: 32px;
		background-color: #48B8B6;
		color: #FFFFFF;
		font-size: 12px;
		padding: 0;
	}

	.exitBt {
		width: 60px;
		height: 32px;
		background-color: #ffffff;
		color: #333333;
		font-size: 12px;
		padding: 0;
	}

	.overAccount_ul {
		width: 490px;
		float: left;
		overflow: hidden;
	}

	.overAccount_ul_title {
		height: 36px;
		font-size: 14px;
		line-height: 36px;
		color: #999999;
	}

	.overAccount_ul .el-input,
	.overAccount_ul .el-select {
		margin-bottom: 7px;
	}

	.ul-left {
		float: left;
		width: 200px;
		margin-left: 24px;
	}

	.ul-right {
		float: right;
		width: 200px;
		margin-right: 26px;
	}
</style>
